import React from "react";
import styled from "@emotion/styled";

const LogoContainer = styled.div`
  display: inline-block;
`;

const ToposLogo = ({ color }) => {
  return (
    <LogoContainer>
      <svg width="46" height="30" viewBox="0 0 46 30">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.00022876 0L0 2.7441L16.1502 2.78585L16.15 4.79564H16.1502V5.57217H0.00022876V8.31604H16.1502V11.0601H15.3766H13.8899H0V13.8045H13.8899H15.3763H16.1502L16.15 30H18.8867V13.8045H19.6606H21.6407V30H24.3769V13.8045H26.3394H27.1136L27.1131 30H29.8498L29.8502 13.8045H46V11.0601H29.8502V8.31604H46V5.57217H29.8502V2.82141L30.6237 2.82348V2.8276H32.333L32.8303 2.82898V2.8276H46V0.0837316H32.333L0.00022876 0ZM18.8867 4.79564V2.82921V2.79296L19.6606 2.7948V2.8276H21.6407V3.60321V5.57217H20.8865H19.6608H18.8867V4.79564ZM24.3769 3.60528V2.8276H26.3394V2.81223L27.1136 2.81407V4.79564V5.57217H24.3769V3.60528ZM24.3769 10.2829V8.31604H27.1136V11.0601H26.3394H24.3769V10.2829ZM18.8867 8.31604H19.6603H20.8865H21.6407V10.2829V11.0601H19.6608H18.8867V8.31604Z"
          fill={color || "var(var(--current-section-text-color), inherit)"}
        />
      </svg>
    </LogoContainer>
  );
};

export default ToposLogo;
